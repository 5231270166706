import React from 'react'
import styled from 'styled-components'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import IntroSection from '../components/main/IntroSection'
import GlobalSection from '../components/main/GlobalSection'
import PartnersSection from '../components/main/PartnersSection'
import InvertColorsContext from '../context/InvertColorsContext'
import AIServiceSection from '../components/main/AIServiceSection'
import MedicalHumanDataSection from '../components/main/MedicalHumanDataSection'
import MedicalTerminologySection from '../components/main/MedicalTerminologySection'
import AIDataEcoSystemSection from '../components/main/AIDataEcoSystemSection'
import TesserNewsSection from '../components/main/TesserNewsSection'
import { useTranslation } from 'react-i18next'
import { modifyLangString } from '../lib/utils/modifyLangString'

const metaData = {
	title: '테서 | Tesser',
	description:
		'인공지능 기술로 환자의 일상을 더 편안하게. 환자와 데이터 기반의 차세대 의료 AI 플랫폼을 만들어갑니다.',
	keywords:
		'tesser, 테서, 태서, 의료 언어모델, 헬스 리터러시, 온톨, 데이터바우처, AI바우처, 병원데이터, 데이터가공, 데이터전처리, 임상데이터, 의료데이터, medical AI, 언어모델, 의료 LLM, 환자 데이터, 의료 데이터, 의료 인공지능, 의료영상, MRI 분할, MRI Segmentation, CT Segmentation, 폐암, 유방암',
}

function Main() {
	const { i18n } = useTranslation()
	
	return (
		<Layout>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<Block>
						<IntroSection invertColors={invertColors} />
						<AIServiceSection />
						<MedicalHumanDataSection />
						<MedicalTerminologySection />
						<AIDataEcoSystemSection />
						<GlobalSection />
						{ /* 한국어일 경우만 지원 */ modifyLangString(i18n.language) === 'ko' && <TesserNewsSection /> }
						<PartnersSection />
					</Block>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 200vh;
	margin: 0 auto;
`

export default Main
