import styled from 'styled-components'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ShowMoreButton from './components/ShowButton'
import { useTranslation } from 'react-i18next'

function AIDataEcoSystemSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			PinkLarge: file(
				relativePath: { eq: "images/main/pink_special_shape.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			PinkMedium: file(
				relativePath: { eq: "images/main/pink_special_shape_medium.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			PinkSmall: file(
				relativePath: { eq: "images/main/pink_special_shape_small.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<Title>{t('AIDataEcoSystemSection.title')}</Title>
			<ContentsBox>
				<ImageLarge
					src={`${data.PinkLarge?.childImageSharp.fluid.src}`}
					alt=""
				/>
				<ImageMedium
					src={`${data.PinkMedium?.childImageSharp.fluid.src}`}
					alt=""
				/>
				<ImageSmall
					src={`${data.PinkSmall?.childImageSharp.fluid.src}`}
					alt=""
				/>
				<Contents>
					<ContentTitle>
						{t('AIDataEcoSystemSection.contentTitle')}
					</ContentTitle>
					<ContentDiscription>
						{t('AIDataEcoSystemSection.contentDescription')}
					</ContentDiscription>
					<ShowMoreButton
						text={`${t('AIDataEcoSystemSection.button')}`}
						href="/contact"
						isBlank={false}
					/>
				</Contents>
			</ContentsBox>
		</Block>
	)
}

export default AIDataEcoSystemSection

const Block = styled.section`
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1261px;
		padding: 112px 104px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 96px 0;
	}

	@media (max-width: 768px) {
		width: 320px;
		padding: 64px 0;
	}
`

const Title = styled.h1`
	white-space: pre-line;
	margin-bottom: 64px;

	@media (min-width: 1440px) {
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;

		margin-bottom: 64px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;

		margin-bottom: 48px;
		padding-right: 60px;
		white-space: pre-wrap;
		word-break: keep-all;
	}
`

const ContentsBox = styled.div`
	display: flex;
	gap: 64px;

	@media (min-width: 1440px) {
		width: 1008px;
		height: 544px;
	}
	@media (min-width: 768px) and (max-width: 1439px) {
		width: 688px;
		height: 544px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 48px;
	}
`

const ImageLarge = styled.img`
	display: none;
	width: 472px;

	@media (min-width: 1440px) {
		display: block;
	}
`

const ImageMedium = styled.img`
	display: none;
	width: 312px;

	@media (min-width: 768px) and (max-width: 1439px) {
		display: block;
	}
`

const ImageSmall = styled.img`
	display: none;
	width: 320px;

	@media (max-width: 768px) {
		display: block;
		left: 0;
	}
`

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;

	margin: auto 0;

	button {
		margin-right: auto;
	}
`

const ContentTitle = styled.h2`
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
`
const ContentDiscription = styled.p`
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
	}
	@media (max-width: 768px) {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
	}
`
