import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import ShowMoreButton from './components/ShowButton'
import { useTranslation } from 'react-i18next'

const API_ENDPOINT = 'https://tesser.ghost.io/ghost/api/content/posts?key=e92da53d5d315507ddb2dff127&include=slug,title,excerpt,feature_image&limit=3&filter=tag:news'
const BLOG_URL = 'https://blog.tesser.io'

interface Post {
	title: string
	excerpt: string
	url: string
	published_at: string
	feature_image:string
}
interface Posts {
	error: boolean 
	posts: Array<Post>
}

interface ImageBoxProps {
	image: string
}

function TesserNewsSection() {
	const { t } = useTranslation()
	const [posts, setPosts] = useState<Posts> ({error: false, posts: []})
	const fetchData = async () => {
		try {
			const res = await fetch(API_ENDPOINT)
			const data = await res.json()

			if (data != null && data.posts != null) {
				setPosts({error:false, posts: data.posts})
			} else if (data.errors != null) {
				setPosts({error: true, posts: []})
			}
		} catch(e) {
			setPosts({error: true, posts: []})
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Block>
			{posts.error === false  && 
			(<>
			<Title>{t('TesserNewsSection.title')}</Title>
			{posts.error === false && posts.posts.map(post => (
				<News onClick={() => window.open(post.url, '_blank')}>
				<ImageBox image={post.feature_image}/>
				<TextBox>
					<NewsTitle>{post.title}</NewsTitle>
					<NewsDescription>
						{post.excerpt}
					</NewsDescription>
					<NewsDate>{new Date(post.published_at).toLocaleDateString() }</NewsDate>
				</TextBox>
				</News>
			))}
			<ButtonWrapper>
				<ShowMoreButton
					text={`${t('TesserNewsSection.button')}`}
					href={BLOG_URL}
					isBlank={true}
				/>
			</ButtonWrapper>
			</>)}
		</Block>
	)
}

export default TesserNewsSection

const Block = styled.section`
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 1261px;
		padding: 112px 104px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 96px 0;
	}

	@media (max-width: 768px) {
		width: 320px;
		padding: 64px 0;
		gap: 24px;
	}
`

const Title = styled.h1`
	@media (min-width: 1440px) {
		margin-bottom: 16px;
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		margin-bottom: 16px;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;
	}

	@media (max-width: 768px) {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;
	}
`

const News = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 40px;

	:hover {
		background-color: #f9f9f9;
	}
	
	@media (min-width: 1440px) {
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 24px;
	}
`

const ImageBox = styled.div<ImageBoxProps>`
	min-width: 320px;
	height: 200px;
	background-image: url(${props => props.image});
	background-position: center;
	background-size: cover;
	background-color: #ececec;
	border: 1px solid #f1f1f1;
`

const TextBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (max-width: 768px) {
		gap: 12px;
	}
`

const NewsTitle = styled.h2`
	@media (min-width: 1440px) {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}
`

const NewsDescription = styled.p`
 	overflow: hidden;
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

	@media (min-width: 1440px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
		height: 64px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		height: 56px;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
	}
`

const NewsDate = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;

	color: #77818b;
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`
