import styled from 'styled-components'
import React from 'react'

import { Link } from 'gatsby'

import Chevron from '../../../assets/icons/arrow-right.svg'
import { buttonHover } from '../../../lib/styles/utils'

const ShowMoreButton = ({
	href,
	text,
	isBlank,
}: {
	href: string
	text: string
	isBlank: boolean
}) => {
	return (
		<Button>
			<Link to={href} target={`${isBlank ? '_blank' : ''}`}>
				<span>{text}</span>
				<span>
					<Chevron />
				</span>
			</Link>
		</Button>
	)
}

export const Button = styled.button`
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;

	background-color: black;
	border-radius: 80px;

	${buttonHover}
	a {
		display: flex;
		align-items: center;
		color: white !important;

		box-sizing: border-box;
		padding: 16px 24px;

		font-weight: 600;

		@media (max-width: 1440px) {
			font-size: 16px;

			@media (min-width: 768px) and (max-width: 1440px) {
				font-size: 14px;
				padding: 13px 20px;
			}
			svg {
				width: 17px;
				height: 17px;
				margin-top: 1px;
				margin-bottom: -1px;
				margin-left: 4px;

				path {
					fill: white;
				}
			}
		}

		@media (max-width: 768px) {
			font-size: 12px;
			padding: 11px 18px;
		}
	}

	svg {
		width: 20px;
		height: 20px;
		margin-top: 1px;
		margin-bottom: -1px;
		margin-left: 4px;

		path {
			fill: white;
		}
	}
`
export default ShowMoreButton
