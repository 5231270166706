import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { document } from 'browser-monads'
import { Globe } from '../../lib/globe/globe.js'
import { down, only } from 'styled-breakpoints'

function GlobeAnimation() {
	const globeVizRef = useRef(document)

	useEffect(() => {
		const globe = new Globe(globeVizRef.current)
		globe.load()
		globe.play()
	}, [])

	return (
		<Block>
			<div ref={globeVizRef}></div>
		</Block>
	)
}

const Block = styled.div`
	canvas {
		position: absolute;
		left: 0;
		transform: translate(calc(-100% + 300px), -32%);
	}
`

export default GlobeAnimation
