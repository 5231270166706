import React, { useEffect } from 'react'
import styled from 'styled-components'
import { up, down, only } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'

import zIndexes from '../../lib/styles/zIndexes'

import GradientCanvas from './GradientCanvas'
import { ColorType } from '../../context/InvertColorsContext'

interface IIntroSectionProps {
	invertColors: (colorType: ColorType) => void
}

function IntroSection({ invertColors }: IIntroSectionProps) {
	const { t } = useTranslation()

	const gradientColors = {
		color1: '#6CD3FF',
		color2: '#AE01FF',
		color3: '#FFC801',
		color4: '#FF285C',
	}

	useEffect(() => {
		invertColors(ColorType.WHITE)
	}, [])

	return (
		<Background>
			<Block>
				<GradientContainer data-sal="zoom-out" data-sal-duration="500">
					<GradientCanvas hasShape={true} gradientColors={gradientColors} />
				</GradientContainer>
				<Introduction>
					<Title>
						{t('IntroSection.title1')}
						{t('IntroSection.title2')}
					</Title>
					<Description>{t('IntroSection.description')}</Description>
				</Introduction>
			</Block>
		</Background>
	)
}

const Background = styled.section`
	position: relative;
`
 
const Block = styled.div`
	margin: 0 auto;
	margin-top: 100px;

	@media (min-width: 1440px) {
		width: 1261px;
		padding: 0px 0px 178px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 0 0 96px;
	}

	@media (max-width: 768px) {
		width: 320px;
		margin-top: 35px;
		padding: 0 0 64px;
	}
`

const GradientContainer = styled.section`
	margin: 0 auto;

	@media (min-width: 1440px) {
		width: 100%;
		height: 450px;

		padding: 0 104px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 120%;
		height: 40vw;
		max-height: 320px;
		margin-left: -10%;
	}
	@media (max-width: 768px) {
		width: 100vw;
		position: absolute;
		left: -20px;
		top: 40px;
		height: 199px;
	}
`

const Introduction = styled.section`
	position: relative;
	z-index: 1;
	margin-top: -55px;

	@media (min-width: 768px) and (max-width: 1440px) {
		margin-top: -35px;
	}

	@media (max-width: 768px) {
		padding: 20px;
		padding-top: 215px;
	}
`

const Title = styled.h1`
	@media (min-width: 1440px) {
		white-space: pre-line;

		font-size: 77px;
		font-weight: 700px;
		line-height: 96px;
		letter-spacing: -3px;

		margin-bottom: 72px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		white-space: pre-line;
		font-size: 52px;
		font-weight: 700;
		line-height: 60px;
		letter-spacing: -2px;
		margin-bottom: 40px;
	}
	@media (max-width: 768px) {
		font-size: 36px;
		font-weight: 700;
		line-height: 48px;
		letter-spacing: -2px;
		padding-right: 10px;
		margin-bottom: 24px;

		br {
			display: none;
		}
	}
`

const Description = styled.p`
	margin-left: auto;
	word-break: break-word;

	@media (min-width: 1440px) {
		width: 760px;
		font-size: 24px;
		line-height: 35px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 633px;
		margin-left:0;
		font-size: 20px;
		line-height: 35px;
	}
	@media (max-width: 768px) {
		font-size: 14px;
		font-style: normal;
		line-height: 20px;
	}
`

export default IntroSection
