import styled from 'styled-components'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ShowMoreButton from './components/ShowButton'
import { useTranslation } from 'react-i18next'
import storage from '../../lib/utils/storage'
import Image from '../common/Image'
import Img from 'gatsby-image'
import { modifyLangString } from '../../lib/utils/modifyLangString'

function MedicalHumanDataSection() {
	const { t, i18n } = useTranslation()
	const currentLanguage =
		modifyLangString(i18n.language) ?? storage.getItem('language')

	const data = useStaticQuery(graphql`
		query {
			RealDoctor: file(relativePath: { eq: "images/main/real_doctor.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Iphone_kr: file(relativePath: { eq: "images/medical/IphoneImage1.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Iphone_en: file(
				relativePath: { eq: "images/medical/IphoneImage1_en.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Shadow: file(relativePath: { eq: "images/medical/Shadows.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Block>
			<Title>{t('MedicalHumanDataSection.title')}</Title>
			<TopDescription>
				{t('MedicalHumanDataSection.topDescription.plain1')}
				<span>{t('MedicalHumanDataSection.topDescription.em')}</span>
				{t('MedicalHumanDataSection.topDescription.plain2')}
			</TopDescription>
			<ImageBox>
				<Shadow src={`${data.Shadow?.childImageSharp.fluid.src}`} />
				<Doctor
					src={`${data.RealDoctor?.childImageSharp.fluid.src}`}
					alt="doctor img"
				/>
				<IphoneBox>
					{currentLanguage === 'en' && (
						// <Image data={{ placeholderImage: data.Iphone_en }} />
						<Img fluid={data.Iphone_en.childImageSharp.fluid} />
					)}
					{currentLanguage === 'ko' && (
						// <Image data={{ placeholderImage: data.Iphone_kr }} />
						<Img fluid={data.Iphone_kr.childImageSharp.fluid} />
					)}
				</IphoneBox>
			</ImageBox>
			<BottomDescription>
				<b>{t('MedicalHumanDataSection.bottomDescription.em')}</b>
				{t('MedicalHumanDataSection.bottomDescription.plain')}
			</BottomDescription>
			<ButtonWrapper>
				<ShowMoreButton
					isBlank={true}
					text={`${t('MedicalHumanDataSection.button')}`}
					href="/tech"
				/>
			</ButtonWrapper>
		</Block>
	)
}

export default MedicalHumanDataSection

const Block = styled.section`
	margin: 0 auto;

	@media (min-width: 1440px) {
		padding: 104px;
		width: 1261px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding: 96px 0;
		width: 688px;
	}
	@media (max-width: 768px) {
		padding: 64px 0;
		width: 320px;
	}
`

const Title = styled.h1`
	white-space: pre-line;

	@media (min-width: 1440px) {
		font-size: 60px;
		font-style: normal;
		font-weight: 700;
		line-height: 72px;

		margin-bottom: 64px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;

		margin-bottom: 64px;
	}
	@media (max-width: 768px) {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px;

		margin-bottom: 32px;
	}
`

const TopDescription = styled.p`
	white-space: pre-line;

	span {
		background: linear-gradient(#2403cb, #05558f, #1687d8);
		opacity: 0.6;
		color: transparent;
		-webkit-background-clip: text;
	}

	@media (min-width: 1440px) {
		font-size: 25px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 25px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}
	@media (max-width: 768px) {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}
`

const ImageBox = styled.div`
	position: relative;
	display: flex;

	@media (min-width: 1440px) {
		height: 860px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		height: 840px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		overflow: visible;
	}
`

const Doctor = styled.img`
	margin-top: 32px;

	@media (min-width: 1440px) {
		width: 800px;
		height: 515px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 448px;
		height: 288px;
	}
	@media (max-width: 768px) {
		width: 320px;
		height: 206px;
	}
`
const IphoneBox = styled.div`
	@media (min-width: 1440px) {
		width: 100%;
		min-height: 917px;
		margin-left: -200px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 100%;
		min-height: 690px;
		margin-left: -100px;
	}
	@media (max-width: 768px) {
		width: 100%;
		min-height: 648px;
		margin-top: -40px;
	}
`

const Shadow = styled.img`
	position: absolute;
	@media (min-width: 1440px) {
		height: 1100px;
		right: -70px;
		top: -110px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		top: -20px;
		right: -35px;
		height: 780px;
	}
	@media (max-width: 768px) {
		height: 870px;
		top: 90px;
		left: -200px;
	}
`

const BottomDescription = styled.p`
	color: #868686;

	b {
		color: #000;
	}

	@media (min-width: 1440px) {
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 40px;

		padding-left: 220px;
		margin-top: 120px;
		margin-right: -112px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: 36px;
	}
	@media (max-width: 768px) {
		display: none;
	}
`

export const ButtonWrapper = styled.div`
	margin-top: 32px;

	@media (min-width: 1440px) {
		margin-left: 220px;
		margin-bottom: 112px;
	}

	@media (max-width: 768px) {
		display: none;
		margin-bottom: 96px;
	}
`
