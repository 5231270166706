import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { up, down, only } from 'styled-breakpoints'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


import GlobeAnimation from './GlobeAnimation'
import container from '../../lib/styles/container'
import zIndexes from '../../lib/styles/zIndexes'

function GlobalSection() {
	const [view, setView] = useState(false)
	const [ref, inView] = useInView({
		threshold: 0,
	})

	const { t } = useTranslation()

	useEffect(() => {
		if (inView === true) {
			setView(true)
		}
		if (inView === false) {
			setView(false)
		}
	}, [inView])

	return (
		<Block ref={ref}>
			<TextSection>
				<H1>{t('GlobalSection.h1')}</H1>
				<DetailBox>
					<DetailItem>
						<div className="title">{t('GlobalSection.title1')}</div>
						<div className="index">6</div>
					</DetailItem>
					<DetailItem>
						<div className="title">{t('GlobalSection.title2')}</div>
						<div className="index">33K+</div>
					</DetailItem>
					<DetailItem>
						<div className="title">{t('GlobalSection.title3')}</div>
						<div className="index">5</div>
					</DetailItem>
					<DetailItem>
						<div className="title">{t('GlobalSection.title4')}</div>
						<div className="index">200K+</div>
					</DetailItem>
				</DetailBox>
			</TextSection>
			<GlobeSection>{view && <GlobeAnimation />}</GlobeSection>
		</Block>
	)
}

const Block = styled.section`
	height: 800px;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: space-between;

	@media (min-width: 1440px) {
		width: 1261px;
		padding: 104px 0;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding: 0;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const TextSection = styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1.5rem 0;
	z-index: ${zIndexes.mainGlobalText};

	.mainText {
		word-break: keep-all;
		${down('xs')} {
			font-size: 40px;
			line-height: 48px;
			font-weight: 700;
		}
	}

	.description {
		margin-top: 32px;
	}
`

const H1 = styled.h1`
	white-space: pre-line;
	@media (min-width: 1440px) {
		font-size: 80px;
		font-style: normal;
		font-weight: 700;
		line-height: 96px;
		letter-spacing: -2px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 56px;
		font-style: normal;
		font-weight: 700;
		line-height: 64px;
	}
	@media (max-width: 768px) {
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 48px;
	}
`

const DetailBox = styled.div`
	display: flex;

	gap: 40px;

	@media (min-width: 1440px) {
		margin-top: 64px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		margin-top: 64px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		margin-top: 32px;
	}
`

const DetailItem = styled.div`
	@media (min-width: 1440px) {
		.title {
			white-space: pre-line;

			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
		}
		.index {
			font-size: 60px;
			font-style: normal;
			font-weight: 700;
			line-height: 72px;
		}
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 142px;

		.title {
			white-space: pre-line;

			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
		}
		.index {
			font-size: 48px;
			font-style: normal;
			font-weight: 700;
			line-height: 56px;
		}
	}
	@media (max-width: 768px) {
		.title {
			white-space: pre-line;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
		}

		.index {
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 44px;
		}
	}
`

const GlobeSection = styled.section`
	position: relative;

	@media (min-width: 1440px) {
		right: -100px;
	}

	@media (min-width: 768px) and (max-width: 1440px) {
		top: 100px;
		left: 150px;
	}

	@media (max-width: 768px) {
		top: 0px;
		left: 210px;
	}
`

export default GlobalSection
